/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// const React = require("react")
// const Layout = require("./src/components/Layout").default
//
// exports.wrapPageElement = ({ element, props }) => {
//   return <Layout {...props}>{element}</Layout>
// }

exports.onClientEntry = () => {
  window.decomp = require('poly-decomp');
}
